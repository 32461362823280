@font-face {
  font-display: swap;
  font-family: "light";
  src: url("./assets/font/ITCAvantGardeStd-light.otf") format("woff");
}

@font-face {
  font-display: swap;
  font-family: "bold";
  src: url("./assets/font/ITCAvantGardeStd-bold.otf") format("woff");
}

@font-face {
  font-display: swap;
  font-family: "bold-italic";
  src: url("./assets/font/ITCAvantGardeStd-bold-italic.otf") format("woff");
}

@font-face {
  font-display: swap;
  font-family: "regular";
  src: url("./assets/font/ITCAvantGardeStd-regular.otf") format("woff");
}